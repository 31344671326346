.emb-team__team {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.emb-team__member {
  width: 33%;
  display: flex;
  margin: 0 0 50px;
}
.emb-team__photocontainer {
  flex: 0 0 80px;
  height: 80px;
}
.emb-team__photo {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.emb-team__nophoto {
  display: block;
  width: 80px;
  height: 80px;
  background: #eee;
  border-radius: 100%;
}
.emb-team__infocontainer {
  padding-left: 25px;
  padding-right: 30px;
}
.emb-team__infocontainer a {
  text-decoration: none;
}
html.non-touch .emb-team__infocontainer a:hover {
  text-decoration: underline;
}
.emb-team__infocontainer .fa {
  color: #6b8f00;
  margin-right: 3px;
  font-size: 15px;
}
.emb-team__name {
  font-size: 28px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  margin: 0 0 5px;
}
.emb-team__position {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 10px;
}
.emb-team__email, .emb-team__phone, .emb-team__whatsapp {
  margin: 0 0 5px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .emb-team__member {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .emb-team__member {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .emb-team__member {
    display: block;
  }
  .emb-team__photocontainer {
    flex: initial;
    width: 100%;
    margin-bottom: 15px;
  }
  .emb-team__infocontainer {
    padding: 0;
  }
  .emb-team__photo {
    width: 80px;
    height: 80px;
  }
}