@charset "UTF-8";
body {
  -webkit-font-smoothing: antialiased;
  color: var(--color-black);
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  font-style: normal;
  font-family: "Inter", sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 900;
}

h1,
.h1 {
  font-size: 48px;
  line-height: 54px;
}

h2,
.h2 {
  font-size: 42px;
  line-height: 48px;
  margin: 5px 0 10px;
}

h3,
.h3 {
  font-size: 32px;
  line-height: 32px;
  margin: 5px 0 8px;
}

p,
.embeddedobject--site,
.wh-video {
  font-size: 16px;
  line-height: 32px;
  margin: 5px 0 15px;
}

a {
  color: var(--color-black);
  text-decoration: underline;
}

ul,
ol {
  padding-left: 1em;
}

ul.unordered2 {
  list-style-type: none;
  padding-left: 0;
}
ul.unordered2 li {
  margin: 0 0 5px 1.5em;
  position: relative;
}

ul.unordered2 li:before {
  margin-right: 2px;
  content: "✓ ";
  color: #6b8f00;
  font-size: 120%;
  position: absolute;
  top: 2px;
  left: -1.3em;
}

.wh-video[data-video].wh-video--aspect_4_3:after {
  padding-top: 75%;
}

.wh-video[data-video].wh-video--aspect_16_9:after {
  padding-top: 56.25%;
}

.wh-video[data-video] {
  position: relative;
  width: 100%;
  cursor: pointer;
  z-index: 0;
}

.wh-video[data-video]:after {
  display: block;
  content: "";
}

hr {
  border: 1px solid #6b8f00;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-button-prev,
.swiper-button-next {
  width: 46px;
  height: 46px;
  background: #fff;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  outline: none;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  width: 6px;
  height: 11px;
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-green-small.svg") no-repeat;
}
html.non-touch .swiper-button-prev:hover,
html.non-touch .swiper-button-next:hover {
  background: #6b8f00;
}
html.non-touch .swiper-button-prev:hover::after,
html.non-touch .swiper-button-next:hover::after {
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-white-small.svg") no-repeat;
}

.swiper-button-prev::after {
  transform: scaleX(-1);
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #fff;
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #6b8f00;
}

.breadcrumb {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
@media (max-width: 1200px) {
  .breadcrumb {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
  html.page-default .breadcrumb {
    padding-left: 0;
    padding-right: 0;
  }
}
.breadcrumb, .breadcrumb__link {
  font-size: 14px;
  line-height: 100%;
  color: #666;
}
.breadcrumb__link {
  text-decoration: underline;
}
html.non-touch .breadcrumb__link.breadcrumb__link--last:hover {
  text-decoration: none;
}
.breadcrumb__link--nolink {
  text-decoration: none;
}
.breadcrumb__divider {
  margin: 0 8px;
  position: relative;
  top: 1px;
}
html.page-search .breadcrumb {
  display: none;
}
@media (max-width: 576px) {
  .breadcrumb {
    display: none;
  }
}

.button, .wh-form__button {
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  display: inline-flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.3s, color 0.3s, border 0.3s, box-shadow 0.3s;
  padding: 0 60px 0 20px;
  outline: none;
  background: #fff;
  border: 1px solid #6b8f00;
  height: 46px;
  text-decoration: none;
  min-width: 152px;
  position: relative;
  border-radius: 23px;
  white-space: nowrap;
  vertical-align: baseline;
  display: inline-flex;
  position: relative;
  padding: 2.1rem 3rem;
  font: 700 1.6rem/2rem "Inter", sans-serif;
  border-radius: 0;
  text-decoration: none;
  margin: 0;
  text-align: center;
  cursor: pointer;
  border: 1px solid #1a1a1a;
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  border-color: #89a517;
  background-color: #89a517;
  color: #fff;
  text-transform: unset;
  font-size: 16px;
  line-height: 100%;
  min-height: 60px;
}
html.non-touch .button:hover, html.non-touch .wh-form__button:hover {
  border-color: #2d6f4c;
  background-color: #2d6f4c;
}
html.non-touch .button:hover::after, html.non-touch .wh-form__button:hover::after {
  background-image: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-white-small.svg");
}
.button::after, .wh-form__button::after {
  content: "";
  display: block;
  width: 6px;
  height: 11px;
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-white-small.svg") no-repeat;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none;
}
html.mt .button::after, html.mt .wh-form__button::after {
  background-image: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-gray-small.svg");
}
html.mt .button--filled {
  color: #fff;
}
html.mt .button--filled::after {
  background-image: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-white-small.svg");
}
@media screen and (max-width: 500px) {
  .button, .wh-form__button {
    white-space: initial;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
  }
}

.greenbgblock {
  position: relative;
}
.greenbgblock:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: red repeat url("./web/img/slootsmid-background.svg");
  position: absolute;
  opacity: 0.04;
  background-size: 22rem;
}

.logo {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo__image {
  display: block;
  height: 69px;
}
.logo__subtext {
  font-size: 15px;
  line-height: 100%;
  text-transform: uppercase;
  color: #6b8f00;
  margin: 10px 0 0;
  letter-spacing: 2.62px;
}

html.page-default .main {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  margin-top: 195px;
}
@media (max-width: 1200px) {
  html.page-default .main {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 480px) {
  html.page-default .main {
    margin-top: 80px;
  }
}

.main {
  margin-top: 195px;
  padding-top: 30px;
}
@media (max-width: 480px) {
  .main {
    margin-top: 80px;
    z-index: 0 !important;
  }
}

.menu {
  position: fixed;
  width: 100%;
  max-width: 1200px;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 700;
  transition: top 0.3s;
  pointer-events: none;
}
html.smallheader .menu {
  top: 60px;
}
.menu__productcats {
  margin: 20px 0 30px 15px;
  display: flex;
}
.menu__productcat {
  padding-right: 30px;
}
.menu #main-menu,
.menu .main-menu-btn {
  pointer-events: auto;
}
.menu #main-menu > li li a {
  transition: color 0.3s;
}
html.non-touch .menu #main-menu > li li a:hover {
  background: none;
  color: #6b8f00;
}
.menu #main-menu > li li .menu__maincat {
  margin: 0 0 10px;
  padding: 10px 20px;
  font-size: 24px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
}
html.mt .menu #main-menu > li li .menu__maincat {
  color: #6b8f00;
}
.menu #main-menu > li li .menu__maincat > a {
  font-size: 24px;
  line-height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.menu #main-menu > li li .menu__subcat a {
  font-size: 14px;
  line-height: 22px;
  font-family: Arial, sans-serif;
  text-transform: initial;
  color: #666;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .menu {
    padding-right: 25px;
  }
}
@media (max-width: 1023px) {
  .menu__productcats {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .menu__maincat {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .menu__productcat {
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .menu {
    top: 46px;
  }
}

@media (max-width: 1023px) {
  .menu #main-menu > li li .menu__maincat {
    margin-bottom: 0;
  }
}
.mobilefilters__button {
  display: none;
  width: 100%;
}
.mobilefilters__button--close {
  margin-top: auto;
}
.mobilefilters__stickyfilterbutton {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: #6b8f00;
  color: #fff;
  width: 65px;
  height: 65px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 30px;
  cursor: pointer;
  border: 1px solid #6b8f00;
  transition: background 0.3s, color 0.3s;
  z-index: 900;
}
html.non-touch .mobilefilters__stickyfilterbutton:hover {
  background: #fff;
  color: #6b8f00;
}
@media screen and (max-width: 767px) {
  .mobilefilters__button--expand {
    display: block;
  }
  html.filters-open .mobilefilters__button--close {
    display: block;
  }
  .mobilefilters__stickyfilterbutton {
    display: flex;
  }
}

.overviewfilters__container {
  font-size: 14px;
  line-height: 100%;
  padding: 20px 0 0;
}
.overviewfilters__type {
  margin: 20px 0 0;
  padding: 0 0 10px;
}
.overviewfilters__type:first-child {
  margin-top: 0;
}
.overviewfilters__title {
  font-size: 14px;
  line-height: 100%;
  display: block;
  margin: 0 0 15px;
  font-weight: 700;
  color: #000;
}
.overviewfilters__title--vat {
  font-weight: 400;
  line-height: 25px;
  margin-top: 15px;
}
.overviewfilters__row {
  display: block;
  margin-bottom: 5px;
}
.overviewfilters__row label {
  font-size: 14px;
  line-height: 100%;
  color: #000;
  line-height: 25px;
  position: relative;
  top: 1px;
}
.overviewfilters__close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
#mobileoverviewfilterscontainer .overviewfilters__close {
  display: block;
}
.overviewfilters__close svg {
  width: 25px;
}
.overviewfilters__close svg .svgclose {
  fill: #6b8f00;
}
.overviewfilters .wh-form__fieldline {
  display: block;
}
.overviewfilters .wh-form__optiondata {
  width: auto;
}
@media screen and (max-width: 767px) {
  .overviewfilters {
    position: fixed;
    top: 0;
    left: -100px;
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    width: 0 !important;
    background: #fff;
    z-index: 100000;
    overflow-y: scroll;
    pointer-events: hidden;
    transition: width 0.4s, left 0.4s;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
  html.filters-open .overviewfilters {
    left: 0;
    width: 90vw !important;
    pointer-events: auto;
  }
}

.previewblock {
  background: #fff;
  width: 33.333333%;
  padding: 40px 30px 60px;
  display: flex;
  flex-direction: column;
}
.previewblock__title {
  font-size: 24px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  text-align: center;
  margin: 0 0 40px;
}
.previewblock__item {
  margin: 0 0 36px;
  display: flex;
  align-items: center;
}
.previewblock__imagecontainer {
  flex: 0 0 150px;
  display: block;
  text-decoration: none;
}
.previewblock__image {
  display: block;
  border-radius: 65px;
  width: 120px;
  height: 120px;
}
.previewblock__info {
  flex: 1;
}
.previewblock__logo {
  max-width: 100px;
  margin: 0 0 5px;
}
.previewblock a.previewblock__itemtitle {
  text-decoration: none;
}
html.non-touch .previewblock a.previewblock__itemtitle:hover {
  text-decoration: underline;
}
.previewblock__itemtitle {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 5px;
  display: inline-block;
}
.previewblock__description {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 35.9999999996px; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.2857142857;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.previewblock__button {
  text-align: center;
  margin-top: auto;
}
.previewblock__buttonlink--mobile {
  display: none;
}
@media screen and (max-width: 1023px) {
  .previewblock__buttonlink--desktop {
    display: none;
  }
  .previewblock__buttonlink--mobile {
    display: inline-flex;
  }
  .previewblock__imagecontainer {
    flex: 0 0 100px;
  }
  .previewblock__image {
    width: 90px;
    height: 90px;
  }
  .previewblock__description {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .previewblock__item {
    flex-direction: column;
    align-items: center;
  }
  .previewblock__imagecontainer {
    flex: initial;
    margin-bottom: 10px;
  }
  .previewblock__image {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 768px) {
  .previewblock__title {
    font-size: 19px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--color-black);
  }
}
@media screen and (max-width: 599px) {
  .previewblock {
    width: 100%;
    border-bottom: 1px solid #a6a9a9;
  }
  .previewblock__title {
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--color-black);
  }
  .previewblock__itemtitle, .previewblock__description {
    text-align: center;
  }
  .previewblock__button {
    text-align: right;
    margin-top: 20px;
  }
  .previewblock__info {
    display: flex;
    flex-direction: column;
  }
  .previewblock__description {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    overflow: visible;
    height: auto;
    -webkit-line-clamp: initial;
  }
  .previewblock__item {
    align-items: center;
  }
  .previewblock__logocontainer {
    text-align: center;
  }
}
@media screen and (max-width: 450px) {
  .previewblock__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .previewblock__logocontainer, .previewblock__description, .previewblock__button {
    text-align: center;
  }
}

.sidebarmenu {
  margin: 0 0 20px;
}
.sidebarmenu .wh-form__fieldline {
  margin-bottom: 0;
}
.sidebarmenu a {
  text-decoration: none;
  color: #666;
  transition: color 0.3s;
}
html.non-touch .sidebarmenu a:hover {
  color: #6b8f00;
}
.sidebarmenu__item {
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0 0 12px;
  color: var(--color-black);
  padding-right: 20px;
  text-transform: unset;
  font-weight: 900;
}
.sidebarmenu__itemlink {
  display: block;
  position: relative;
}
.sidebarmenu__itemlink.selected {
  color: #6b8f00;
}
.sidebarmenu__itemlink:after {
  content: "";
  position: absolute;
  top: 7px;
  right: 0;
  background: no-repeat url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-up-gray.svg");
  width: 11px;
  height: 6px;
  transform: scaleY(-1);
  transition: transform 0.3s;
}
.sidebarmenu__itemlink--noexpandicon::after {
  display: none;
}
.sidebarmenu__subitems {
  overflow: hidden;
  max-height: 0;
}
.sidebarmenu__subitem a {
  font-size: 14px;
  line-height: 24px;
}
.sidebarmenu__subitem a.selected {
  color: var(--color-black);
  font-weight: 700;
}
.sidebarmenu.expanded .sidebarmenu__itemlink:after {
  transform: none;
}
.sidebarmenu.expanded .sidebarmenu__subitems {
  max-height: 100%;
}

.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.wh-rtd--remove-topbottom-margin > *:first-child {
  top-bottom: 0;
}
.wh-rtd--remove-topbottom-margin > *:last-child {
  margin-bottom: 0;
}

@font-face {
  font-family: "lg";
  src: url("./lightgallery/fonts/lg.eot?n1z373");
  src: url("./lightgallery/fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("./lightgallery/fonts/lg.woff?n1z373") format("woff"), url("./lightgallery/fonts/lg.ttf?n1z373") format("truetype"), url("./lightgallery/fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.lg-icon {
  font-family: "lg";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
}
.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #fff;
}
.lg-actions .lg-next {
  right: 20px;
}
.lg-actions .lg-next:before {
  content: "\e095";
}
.lg-actions .lg-prev {
  left: 20px;
}
.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close:after {
  content: "\e070";
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}
.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}
.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: rgb(169, 7, 7);
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}
.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}
.lg-outer .lg-toggle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}
.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}
.lg-outer .lg-toggle-thumb:hover {
  color: #fff;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}
.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("./lightgallery/img/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("./lightgallery/img/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("./lightgallery/img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("./lightgallery/img/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("./lightgallery/img/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}
.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}
.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}
.lg-progress-bar .lg-progress {
  background-color: rgb(169, 7, 7);
  height: 5px;
  width: 0;
}
.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}
.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}
.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}
#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}
#lg-zoom-out:after {
  content: "\e312";
}
.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}
.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}
.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}
.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}
.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}
.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}
.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}
.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}
.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}
.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}
.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}
.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}
.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}
.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}
.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}
.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}
.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}
.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}
.lg-outer #lg-share {
  position: relative;
}
.lg-outer #lg-share:after {
  content: "\e80d";
}
.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}
.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}
.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}
.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}
.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}
.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}
.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}
.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}

.group {
  *zoom: 1;
}

.group:before, .group:after {
  display: table;
  content: "";
  line-height: 0;
}

.group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}
.lg-outer .lg-item {
  background: url("./lightgallery/img/loading.gif") no-repeat scroll center center transparent;
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}
.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-empty-html {
  display: none;
}
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-zoom-in .lg-item {
  opacity: 0;
}
.lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}
.lg-css3.lg-zoom-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3);
}
.lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-zoom-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0;
}
.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}
.lg-css3.lg-zoom-in-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}
.lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-zoom-out .lg-item {
  opacity: 0;
}
.lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}
.lg-css3.lg-zoom-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}
.lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-zoom-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0;
}
.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.lg-css3.lg-zoom-out-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0;
}
.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}
.lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0;
}
.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2);
}
.lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0;
}
.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}
.lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}
.lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide, .lg-css3.lg-soft-zoom .lg-item.lg-next-slide, .lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-scale-up .lg-item {
  opacity: 0;
}
.lg-css3.lg-scale-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
}
.lg-css3.lg-scale-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
}
.lg-css3.lg-scale-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-scale-up .lg-item.lg-prev-slide, .lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-circular .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide-circular .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}
.lg-css3.lg-slide-circular .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-circular .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular .lg-item.lg-next-slide, .lg-css3.lg-slide-circular .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-circular-up .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
}
.lg-css3.lg-slide-circular-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
}
.lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-circular-down .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
}
.lg-css3.lg-slide-circular-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
}
.lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
}
.lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
}
.lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-circular-vertical-left .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
}
.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
}
.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-circular-vertical-down .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
}
.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
}
.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
}
.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
.lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
}
.lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
}
.lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}
.lg-css3.lg-slide-skew-only .lg-item.lg-next-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg);
}
.lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}
.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg);
}
.lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg);
}
.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}
.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg);
}
.lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg);
}
.lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}
.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
}
.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
}
.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1;
}
.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
}
.lg-css3.lg-slide-skew .lg-item.lg-next-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
}
.lg-css3.lg-slide-skew .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew .lg-item.lg-next-slide, .lg-css3.lg-slide-skew .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
}
.lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
}
.lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
}
.lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
}
.lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
}
.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
}
.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
}
.lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
}
.lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
}
.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
}
.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
}
.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
}
.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
}
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
}
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1;
}
.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-lollipop .lg-item {
  opacity: 0;
}
.lg-css3.lg-lollipop .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-lollipop .lg-item.lg-next-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5);
}
.lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-lollipop .lg-item.lg-prev-slide, .lg-css3.lg-lollipop .lg-item.lg-next-slide, .lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5);
}
.lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-rotate .lg-item {
  opacity: 0;
}
.lg-css3.lg-rotate .lg-item.lg-prev-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.lg-css3.lg-rotate .lg-item.lg-next-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.lg-css3.lg-rotate .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}
.lg-css3.lg-rotate .lg-item.lg-prev-slide, .lg-css3.lg-rotate .lg-item.lg-next-slide, .lg-css3.lg-rotate .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0;
}
.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.lg-css3.lg-rotate-rev .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}
.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide, .lg-css3.lg-rotate-rev .lg-item.lg-next-slide, .lg-css3.lg-rotate-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-tube .lg-item {
  opacity: 0;
}
.lg-css3.lg-tube .lg-item.lg-prev-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
}
.lg-css3.lg-tube .lg-item.lg-next-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
}
.lg-css3.lg-tube .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-tube .lg-item.lg-prev-slide, .lg-css3.lg-tube .lg-item.lg-next-slide, .lg-css3.lg-tube .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.footer {
  color: #fff;
  color: #000;
  margin-top: 100px;
  position: relative;
}
html.page-home.mt .footer {
  margin-top: 30px;
}
.footer__container {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .footer__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.footer__copyright {
  text-align: right;
  padding: 0 0 30px;
}
.footer__copyright a {
  text-decoration: none;
  color: inherit;
}
html.non-touch .footer__copyright a:hover {
  text-decoration: underline;
}
.footer__cols {
  display: flex;
  justify-content: space-between;
  padding: 55px 0;
}
.footer__col--image-logo, .footer__col--description {
  width: 282px;
}
.footer__col--image-logo {
  position: relative;
}
.footer__col--image-logo .logo {
  margin-top: 110px;
  position: relative;
  z-index: 10;
}
.footer__col--image-logo .logo__subtext {
  color: #fff;
  color: #000;
  line-height: 140%;
  margin-top: 16px;
}
.footer__col--description {
  border-right: 1px solid #fff;
  border-right: 1px solid #000;
}
.footer__col--links, .footer__col--contact {
  width: 180px;
  border-right: 1px solid #fff;
  border-right: 1px solid #000;
  letter-spacing: 0.75px;
  font-weight: 700;
}
.footer__col--links {
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
}
.footer__col--links2 {
  border: 0;
}
.footer__col--contact {
  font-size: 11px;
  line-height: 18px;
}
.footer__col--contact a {
  color: inherit;
}
.footer__locations {
  font-size: 12px;
  line-height: 26px;
}
.footer__locations p {
  margin: 0;
}
.footer__locations p.heading {
  font-size: 13px;
}
html.mt .footer__locations a {
  color: #000;
}
html.mt .footer__locations p.heading {
  color: #fff;
  color: #000;
}
.footer__image {
  position: absolute;
  top: -80px;
  left: 0;
  max-width: 100%;
  transform: rotate(-5deg);
}
.footer__description {
  font-size: 12px;
  line-height: 15px;
  padding-right: 18px;
}
.footer a {
  text-decoration: none;
}
html.non-touch .footer a:hover {
  text-decoration: underline;
}
.footer__link {
  font-size: 12px;
  line-height: 26px;
  color: inherit;
}
.footer__link--header {
  font-size: 25px;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 1.87px;
  display: inline-block;
  margin: 0 0 13px;
  color: #fff;
  color: #000;
}
.footer__link--white {
  color: #fff;
  color: #000;
}
.footer__link--multiline {
  line-height: 20px;
}
.footer__locationtitle {
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
}
html.mt .footer {
  color: #000;
}
html.mt .footer a:hover {
  text-decoration: none;
}
html.mt .footer__link--header {
  color: #fff;
  color: #000;
}
html.mt .footer__link--black {
  color: #000;
  transition: color 0.3s;
}
html.mt .footer__link--black:hover {
  color: #fff;
  color: #000;
}
html.mt .footer__link--white {
  color: #fff;
  color: #000;
  transition: color 0.3s;
}
html.mt .footer__link--white:hover {
  color: #000;
}
html.mt .footer__col--links, html.mt .footer__col--contact, html.mt .footer__col--description {
  border-right: 1px solid #000;
}
html.mt .footer__col--links2 {
  border: 0;
}
html.mt .footer__copyright a {
  color: #000;
}
@media screen and (max-width: 600px) {
  html.mt .footer__col {
    border: 0;
  }
}
@media screen and (max-width: 1200px) {
  .footer__col--description {
    display: none;
  }
  .footer__col--links, .footer__col--contact {
    flex: 1;
    padding-left: 50px;
  }
}
@media screen and (max-width: 1025px) {
  .footer__col--links, .footer__col--contact {
    padding-left: 20px;
  }
}
@media screen and (max-width: 900px) {
  .footer__col--image-logo {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .footer__cols {
    flex-direction: column;
    flex-flow: row wrap;
    width: 100%;
    max-width: initial;
  }
  .footer__col {
    border: 0;
    width: 100%;
    margin-bottom: 25px;
    padding-left: 0;
  }
  .footer__col--contact {
    order: 1;
    flex: initial;
    padding-right: 0;
  }
  .footer__col--links {
    width: 50%;
    order: 2;
  }
  .footer__col--links2 {
    padding-left: 0;
    padding-right: 0;
  }
  .footer__location {
    width: 50%;
    padding-right: 20px;
  }
}
@media screen and (max-width: 400px) {
  .footer__cols {
    display: block;
  }
  .footer__col {
    width: 100%;
    margin-bottom: 15px;
  }
  .footer__location {
    width: 100%;
  }
  .footer__location:last-child {
    margin-bottom: 0;
  }
}

.footer__copyright {
  display: none;
}

.footer {
  padding-bottom: 0px;
}

.footer__landscape {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100px);
  z-index: 40;
}
@media (min-width: 1200px) {
  .footer__landscape {
    transform: translateY(150px);
  }
}
@media (min-width: 1400px) {
  .footer__landscape {
    transform: translateY(200px);
  }
}
@media (min-width: 1800px) {
  .footer__landscape {
    transform: translateY(250px);
  }
}
.footer__landscape::after {
  content: "";
  position: absolute;
  background-color: #acbc2b;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 70px;
  z-index: -1;
}
@media (min-width: 1000px) {
  .footer__landscape::after {
    height: 100px;
  }
}
@media (min-width: 1200px) {
  .footer__landscape::after {
    height: 6vw;
  }
}

.footer__landscape-img {
  width: 100%;
  transform: translateY(10px);
}

:root {
  --color-black: #1a1a1a;
  --color-green: #6b8f00;
  --color-green-dark: #2d6f4c;
}

.mydialog {
  background: white;
  padding: 20px;
  position: relative;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.mydialog__modalbg {
  z-index: 99999;
}

.b-lazy {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */
}

#sitewrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
html:not(.isie) #sitewrapper .main {
  flex: 1;
}
#sitewrapper::before {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s;
}
html.filters-open #sitewrapper::before {
  opacity: 1;
  pointer-events: auto;
}

.mfp-bg {
  z-index: 10000;
}

.mfp-wrap {
  z-index: 10001;
}

#loader {
  position: fixed;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: background 0.5s;
  visibility: hidden;
}
#loader.show {
  background-color: rgba(0, 0, 0, 0.9);
  pointer-events: auto;
  visibility: visible;
}

.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: block;
  background-color: #0b0b0b;
  left: 50%;
  top: 50%;
  margin-left: -37px;
  margin-top: -25px;
  opacity: 0.75;
  width: 75px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 12px;
  transition: opacity 0.3s;
}
.wh-video__playbutton::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20.8px;
  border-color: transparent transparent transparent #fff;
  margin: 0 auto;
  margin-top: 12px;
}

.wh-video__innerframe__preview:hover .wh-video__playbutto {
  opacity: 1;
}

.lg-thumb.group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

#mobileoverviewfilterscontainer {
  display: none;
}
@media screen and (max-width: 767px) {
  #mobileoverviewfilterscontainer {
    display: block;
  }
}

.cookiewallcontainer a.button, .cookiewallcontainer a.wh-form__button {
  text-decoration: none;
}

.wh-video.iframe--cookiewalled {
  margin-bottom: 30px;
}

.wh-video.iframe--cookiewalled:after {
  display: none;
}