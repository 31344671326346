.emb-carousel .swiper-button-prev,
.emb-carousel .swiper-button-next {
  display: none;
}
.emb-carousel--has-slides .swiper-button-prev,
.emb-carousel--has-slides .swiper-button-next {
  display: flex;
}
.emb-carousel .swiper-slide {
  width: 100% !important;
}
.emb-carousel .swiper-slide[data-portrait=true] .emb-carousel__slideimg {
  width: auto;
  height: 100%;
}
.emb-carousel__slidebg {
  background: #6b8f00;
  height: 0;
  overflow: hidden;
  position: relative;
}
.emb-carousel__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emb-carousel__slideimg {
  width: 100%;
  height: auto;
}