.p-dealer-locator .p-map-container {
  position: relative;
}
.p-dealer-locator #google-maps-container {
  width: 100vw;
  height: 500px;
}
.p-dealer-locator .p-dealerinfo {
  background: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.16);
  margin-left: 50px;
  margin-top: -125px;
  padding: 25px 25px 40px;
  position: absolute;
  width: 300px;
}
.p-dealer-locator .p-dealerinfo__close {
  float: right;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__close {
  float: left;
}
@media (max-width: 768px) {
  .p-dealer-locator .p-dealerinfo {
    margin-left: -124px;
    margin-top: -175px;
  }
}