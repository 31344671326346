.p-productspage {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .p-productspage {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-productspage .mobilefilters__button--expand {
  margin-bottom: 30px;
}
.p-productspage .p-products__categories h1,
.p-productspage .p-products__products h1 {
  text-transform: initial;
  margin-bottom: 24px;
}
html.mt .p-productspage .p-products__categories h1,
html.mt .p-productspage .p-products__products h1 {
  color: #6b8f00;
}
.p-productspage .p-products {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.p-productspage .p-products__empty {
  margin: 5px 0 0 20px;
}
.p-productspage .p-products__filters {
  width: 250px;
}
.p-productspage .p-products #products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.p-productspage .p-products #products-grid.show {
  opacity: 1;
  pointer-events: auto;
}
.p-productspage .p-products__products {
  flex: 1;
  border-left: 1px solid #6b8f00;
  padding-left: 24px;
}
.p-productspage .p-products__products .grid-sizer,
.p-productspage .p-products__products .grid-item {
  width: 100%;
}
html.page-products .p-productspage .p-products__products .grid-sizer,
html.page-products .p-productspage .p-products__products .grid-item {
  width: 100%;
}
.p-productspage .p-products__products .grid-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}
html.page-products .p-productspage .p-products__products .grid-item {
  flex-direction: row;
  margin-bottom: 0;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-productspage .p-products__products {
    padding-left: 0;
    border-left: 0;
    flex: initial;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .p-productspage .p-products__filters {
    width: 200px;
  }
}
.p-productspage .p-product {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 40px 0 40px 0;
  border-bottom: 1px solid #a6a9a9;
  transition: background 0.3s;
  position: relative;
  color: inherit;
}
.p-productspage .p-product--first {
  padding-top: 10px;
}
html:not(.non-touch) .p-productspage .p-product {
  padding-left: 0;
}
.p-productspage .p-product::after,
.p-productspage .p-product .fa-external-link {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.p-productspage .p-product::after {
  content: "";
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-green-small.svg") no-repeat;
  width: 6px;
  height: 11px;
}
.p-productspage .p-product .fa-external-link {
  display: none;
  color: #6b8f00;
}
.p-productspage .p-product--external .fa-external-link {
  display: inline;
}
.p-productspage .p-product--external::after {
  display: none;
}
html.non-touch .p-productspage .p-product:hover {
  background: rgba(232, 240, 207, 0.5);
}
.p-productspage .p-product__imagelogo {
  display: flex;
  justify-content: space-between;
  width: 490px;
}
.p-productspage .p-product__title {
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  margin: 0 0 20px;
}
.p-productspage .p-product__logo-container {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.p-productspage .p-product__logo {
  max-width: 111px;
}
.p-productspage .p-product__logo-exclusive {
  font-size: 15px;
  line-height: 100%;
  text-transform: uppercase;
  color: #808080;
  letter-spacing: 0.02px;
  text-align: center;
  max-width: 110px;
  margin: 17px 0 0;
}
.p-productspage .p-product__info-container {
  width: 384px;
  max-width: 100%;
}
.p-productspage .p-product__image {
  width: 282px;
  height: 166px;
}
.p-productspage .p-product__description {
  padding-right: 35px;
}
@media screen and (max-width: 1050px) {
  .p-productspage .p-product__image {
    width: 211.5px;
    height: 124.5px;
  }
}
@media screen and (max-width: 970px) {
  .p-productspage .p-product {
    align-items: flex-start;
  }
  .p-productspage .p-product__imagelogo {
    flex-direction: column;
    width: auto;
  }
  .p-productspage .p-product__image-container {
    margin-bottom: 20px;
  }
  .p-productspage .p-product__info-container {
    padding-left: 20px;
  }
  .p-productspage .p-product__logo-container {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .p-productspage .p-product {
    flex-direction: column !important;
    padding-top: 0;
    padding-left: 0;
    align-items: initial;
  }
  .p-productspage .p-product:last-child {
    border: 0;
  }
  .p-productspage .p-product::after {
    display: none;
  }
  .p-productspage .p-product__image {
    width: 100%;
    height: auto;
    padding-top: 56%;
  }
  .p-productspage .p-product__imagelogo {
    display: block;
    margin-top: 25px;
  }
  .p-productspage .p-product__logo-container {
    margin-bottom: 25px;
  }
  .p-productspage .p-product__info-container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .p-productspage .p-product__description {
    padding-right: 0;
  }
}
.p-productspage .p-category__image {
  display: block;
  max-width: 100%;
  margin: 0 0 15px;
}
.p-productspage .p-category__title {
  font-size: 27px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  letter-spacing: 0.02px;
  margin: 0 0 10px;
}
.p-productspage .p-category__subcat {
  font-size: 14px;
  line-height: 30px;
  display: block;
  text-decoration: none;
  color: #666;
  position: relative;
}
.p-productspage .p-category__subcat:before {
  content: "";
  background: rgba(232, 240, 207, 0.5);
  position: absolute;
  top: 0;
  left: -10px;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.p-productspage .p-category__subcat:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-right-green-small.svg") no-repeat;
  width: 6px;
  height: 11px;
  opacity: 0;
}
html.non-touch .p-productspage .p-category__subcat:hover {
  color: #4e9500;
}
html.non-touch .p-productspage .p-category__subcat:hover:before, html.non-touch .p-productspage .p-category__subcat:hover:after {
  opacity: 1;
}
.p-productspage .p-category__subcat:before:hover {
  opacity: 1;
}
html.non-touch .p-productspage .p-category__subcat:hover {
  font-weight: bold;
}
html.mt .p-productspage .p-category__title {
  color: #6b8f00;
}

.p-productpage__backlink {
  padding: 10px 0 30px;
}
.p-productpage__backlink a {
  font-size: 15px;
  line-height: 100%;
  color: var(--color-black);
  font-weight: bold;
  text-decoration: none;
}
.p-productpage .p-grid {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .p-productpage .p-grid {
    width: 100%;
  }
}
.p-productpage .p-grid__left {
  width: 894px;
}
.p-productpage .p-grid__right {
  width: 282px;
  padding: 0 0 80px 40px;
}
.p-productpage .p-grid .breadcrumb {
  margin-bottom: 46px;
}
.p-productpage .p-brand__image {
  display: block;
  max-width: 100%;
}
.p-productpage .p-brand__info {
  padding: 32px 20px 50px;
  background: #f3f7e7;
  position: relative;
}
.p-productpage .p-brand__logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 165px;
}
.p-productpage .p-brand__website-button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
  padding-right: 40px;
}
.p-productpage .p-buttons {
  margin: 50px 0 0;
  display: flex;
  flex-flow: wrap;
}
.p-productpage .p-buttons__button {
  margin: 0 30px 25px 0;
}
@media screen and (max-width: 1000px) {
  .p-productpage .p-grid__left {
    width: 100%;
  }
  .p-productpage .p-grid__right {
    display: none;
  }
}

html.no-filters .p-productspage .js-collapse-filter {
  display: none;
}

@media print {
  .p-productpage .p-grid {
    width: 100%;
    display: block;
  }
  .p-productpage .p-grid__left {
    width: 100%;
  }
  .p-productpage .p-brand,
  .p-productpage .p-buttons {
    display: none;
  }
}