.slideshow--has-overlay .slideshow__bgoverlay {
  background-image: linear-gradient(90deg, rgba(76, 127, 14, 0.69) 4%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  right: 0;
  bottom: 0;
}

.slideshow {
  position: relative;
  max-width: 1246px;
  margin: 0 auto;
}
.slideshow__item {
  width: 100%;
  height: 643px;
}
.slideshow__bgoverlay, .slideshow__bgimage {
  position: absolute;
  top: 0;
  left: 0;
}
.slideshow__bgimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.slideshow__bgoverlay {
  z-index: 1;
  right: 0;
  bottom: 0;
}
.slideshow__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  position: relative;
  z-index: 2;
  margin-left: 130px;
  max-width: 282px;
}
.slideshow__title {
  margin: 0 0 5px;
}
.slideshow__subtitle {
  margin: 0 0 15px;
}
.slideshow__description {
  margin: 0 0 30px;
}
.slideshow__brand {
  margin: 0 0 10px;
}
.slideshow__brand img {
  max-width: 100px;
  height: auto;
}
.slideshow .swiper-button-next {
  right: 25px;
}
.slideshow .swiper-button-prev {
  left: 25px;
}
.slideshow .swiper-pagination-bullets {
  bottom: 50px;
}
.slideshow .container {
  text-align: center;
  max-width: 60vw;
}
.slideshow .swiper-button-prev,
.slideshow .swiper-button-next {
  display: none;
}
.slideshow--has-slides .swiper-button-prev,
.slideshow--has-slides .swiper-button-next {
  display: flex;
}
.slideshow--fullwidth {
  max-width: 100vw;
  width: 100vw;
}
.slideshow--fullwidth .slideshow__info {
  margin: 0 auto;
  width: 100%;
  max-width: 996px;
}
.slideshow--fullwidth .slideshow__info-inner {
  max-width: 486px;
}
.slideshow--fullwidth .swiper-button-prev,
.slideshow--fullwidth .swiper-button-next {
  left: 50%;
  right: auto;
}
.slideshow--fullwidth .swiper-button-prev {
  margin-left: -600px;
}
.slideshow--fullwidth .swiper-button-next {
  margin-left: 550px;
}
@media screen and (max-width: 1200px) {
  .slideshow--fullwidth .swiper-button-prev {
    margin-left: -550px;
  }
  .slideshow--fullwidth .swiper-button-next {
    margin-left: 400px;
  }
}
@media screen and (max-width: 1145px) {
  .slideshow--fullwidth .swiper-button-prev {
    margin-left: -500px;
  }
  .slideshow--fullwidth .swiper-button-next {
    margin-left: 400px;
  }
  .slideshow--fullwidth .slideshow__info-inner {
    width: 280px;
  }
  .slideshow--fullwidth .slideshow__subtitle {
    font-size: 40px;
    line-height: 34px;
  }
}
@media screen and (max-width: 1045px) {
  .slideshow--fullwidth .swiper-button-prev {
    margin-left: -460px;
  }
}
@media screen and (max-width: 1200px) {
  .slideshow--fullwidth .slideshow__info {
    margin: 0 auto;
    width: 100%;
    max-width: 996px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width: 500px) {
  .slideshow .swiper-button-prev,
  .slideshow .swiper-button-next {
    display: none;
  }
  .slideshow__info {
    margin: 0 auto;
    width: 1200px;
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) and (max-width: 1200px) {
  .slideshow__info {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width: 568px) {
  .slideshow .container {
    max-width: none;
    padding: 0 20px;
  }
}