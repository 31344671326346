.p-search {
  margin: 40px 0 50px;
}
.p-search__heading {
  margin: 0 0 20px;
}
.p-search__resulttitle {
  margin: 0 0 20px;
}
.p-search .p-form {
  margin: 0 0 20px;
  display: flex;
  flex-flow: wrap;
}
.p-search .p-form .wh-form__textinput {
  width: 200px;
  margin: 0 10px 0 0;
}
.p-search .p-form__formsearchbutton {
  padding-left: 30px;
  padding-right: 30px;
  min-width: initial;
}
.p-search .p-form__formsearchbutton::after {
  display: none;
}
.p-search .p-results__titlecontainer {
  margin: 0 0 5px;
}
.p-search .p-results__title {
  text-decoration: none;
  font-size: 160%;
}
html.non-touch .p-search .p-results__title:hover {
  text-decoration: underline;
}
.p-search .p-results__row {
  margin: 0 0 35px;
}
.p-search .p-results__linkcontainer {
  margin: 0 0 6px;
}
.p-search .p-results__link {
  text-decoration: none;
}
html.non-touch .p-search .p-results__link:hover {
  text-decoration: underline;
}
.p-search .p-results .match {
  font-weight: 700;
}