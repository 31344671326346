.p-occasionspage {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .p-occasionspage {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-occasionspage .mobilefilters__button--expand {
  margin-bottom: 30px;
}
.p-occasionspage .p-occasions__categories h1,
.p-occasionspage .p-occasions__occasions h1 {
  font-size: 60px;
  line-height: 100%;
  color: var(--color-black);
  text-transform: initial;
  margin: 0 0 40px;
}
.p-occasionspage .p-occasions {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.p-occasionspage .p-occasions__empty {
  margin: 5px 0 0 20px;
}
.p-occasionspage .p-occasions__filters {
  width: 250px;
}
.p-occasionspage .p-occasions #occasions-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: stretch;
}
.p-occasionspage .p-occasions__occasions {
  flex: 1;
  border-left: 1px solid #6b8f00;
  padding-left: 24px;
}
.p-occasionspage .p-occasions__occasions h1 {
  margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
  .p-occasionspage .p-occasions__filters {
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .p-occasionspage .p-occasions__occasions {
    border: 0;
    padding-left: 0;
  }
}
.p-occasionspage .p-occasion {
  flex: 0 1 auto;
  width: 33.33333333%;
  min-height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.3s;
  text-decoration: none;
  color: inherit;
  padding-bottom: 30px;
}
html.non-touch .p-occasionspage .p-occasion:hover {
  background: rgba(232, 240, 207, 0.5);
}
.p-occasionspage .p-occasion__image {
  display: block;
  padding: 15px 0;
  width: calc(100% - 15px);
  height: auto;
}
.p-occasionspage .p-occasion__info {
  width: 100%;
  display: block;
  text-align: left;
  padding: 0 20px;
}
.p-occasionspage .p-occasion__title {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
}
.p-occasionspage .p-occasion__price {
  margin: 15px 0 0;
}
.p-occasionspage .p-occasion__mainprice {
  font-size: 20px;
  font-weight: 700;
}
@media screen and (max-width: 1150px) {
  .p-occasionspage .p-occasion {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .p-occasionspage .p-occasion {
    width: 100%;
  }
  .p-occasionspage .p-occasion__image {
    width: 100%;
  }
  .p-occasionspage .p-occasion__info {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-occasionpage {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .p-occasionpage {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-occasionpage__grid {
  display: flex;
}
.p-occasionpage__grid--noimages .p-occasionpage__right {
  padding-left: 0;
}
.p-occasionpage__left {
  width: 50%;
}
.p-occasionpage__right {
  padding-left: 78px;
  width: 50%;
}
.p-occasionpage .p-photos {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
}
.p-occasionpage .p-photos__photolink {
  cursor: pointer;
  display: block;
  margin: 0 5px 9px 0;
}
.p-occasionpage .p-photos__photolink:first-child {
  width: 100%;
}
.p-occasionpage .p-photos__photo {
  display: block;
  width: auto;
  height: 100px;
}
.p-occasionpage .p-photos__photo--first {
  width: 100%;
  height: auto;
}
.p-occasionpage .p-info__exchangeinquire {
  margin-top: 2rem;
}
.p-occasionpage .p-info__price {
  margin: 0 0 15px;
}
.p-occasionpage .p-info__field {
  display: flex;
  margin: 0 0 10px;
}
.p-occasionpage .p-info__label, .p-occasionpage .p-info__value {
  display: flex;
}
.p-occasionpage .p-info__label {
  width: 150px;
  align-items: flex-start;
}
.p-occasionpage .p-info__value {
  padding-left: 25px;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .p-occasionpage__right {
    padding-left: 30px;
  }
}
@media screen and (max-width: 900px) {
  .p-occasionpage__right {
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .p-occasionpage .p-info__label {
    width: 100px;
  }
}
@media screen and (max-width: 700px) {
  .p-occasionpage__grid {
    flex-direction: column;
  }
  .p-occasionpage__left, .p-occasionpage__right {
    width: 100%;
  }
  .p-occasionpage__left {
    order: 2;
  }
  .p-occasionpage__right {
    padding-left: 0;
    margin-bottom: 35px;
  }
  .p-occasionpage .p-info__label {
    width: 150px;
  }
}
@media screen and (max-width: 500px) {
  .p-occasionpage .p-info__label {
    width: 100px;
  }
}
@media screen and (max-width: 400px) {
  .p-occasionpage .p-info__field {
    flex-direction: column;
  }
  .p-occasionpage .p-info__field > * {
    width: 100%;
  }
  .p-occasionpage .p-info__value {
    padding-left: 0;
  }
}

@media print {
  .p-productpage .p-grid {
    width: 100%;
    display: block;
  }
  .p-productpage .p-grid__left {
    width: 100%;
  }
  .p-productpage .p-brand,
  .p-productpage .p-buttons {
    display: none;
  }
}
.d-none,
.hidden {
  display: none;
}