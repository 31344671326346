.p-promotionsindex {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .p-promotionsindex {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.p-promotionsindex .p-items__items {
  list-style-type: none;
  margin: 25px 0 0;
  padding: 0;
}
.p-promotionsindex .p-items__item-li {
  margin-bottom: 40px;
}
.p-promotionsindex .p-items__item {
  margin: 10px 0 25px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.p-promotionsindex .p-items__image-container {
  width: 25%;
}
.p-promotionsindex .p-items__image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}
.p-promotionsindex .p-items__image-link {
  overflow: hidden;
  display: block;
}
html.non-touch .p-promotionsindex .p-items__image-link:hover .p-items__image {
  transform: scale(1.1);
}
.p-promotionsindex .p-items__info {
  width: 75%;
  padding-left: 25px;
}
.p-promotionsindex .p-items__date {
  margin-bottom: 10px;
}
.p-promotionsindex .p-items__heading, .p-promotionsindex .p-items__heading-link {
  margin-top: 0;
}
.p-promotionsindex .p-items__heading-link {
  font-size: 27px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  text-decoration: none;
}
.p-promotionsindex .p-items__readmore-container {
  text-align: left;
  margin-top: 10px;
}
html.mt .p-promotionsindex .p-items__heading-link {
  color: #6b8f00;
}
@media screen and (max-width: 768px) {
  .p-promotionsindex .p-items__image-container {
    width: 100%;
  }
  .p-promotionsindex .p-items__info {
    width: 100%;
    padding-left: 0;
  }
}
.p-promotionsindex .p-items__item-li {
  margin-bottom: 60px;
}

.p-promotionsdetails .p-back {
  margin: 0 0 25px;
}