.emb-twocolumnstext__cols {
  display: flex;
  justify-content: space-between;
}
.emb-twocolumnstext__col {
  width: 50%;
}
.emb-twocolumnstext__col--left {
  margin-left: 18px;
}
.emb-twocolumnstext__col--right {
  margin-left: 18px;
}
.emb-twocolumnstext__col .wh-rtd > *:first-child {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .emb-twocolumnstext__cols {
    flex-direction: column;
  }
  .emb-twocolumnstext__cols--mobrightfirst .emb-twocolumnstext__col--left {
    order: 2;
    margin-bottom: 0;
  }
  .emb-twocolumnstext__cols--mobrightfirst .emb-twocolumnstext__col--right {
    margin-bottom: 25px;
  }
  .emb-twocolumnstext__col {
    width: 100%;
  }
  .emb-twocolumnstext__col--left {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .emb-twocolumnstext__col--right {
    margin-left: 0;
  }
}