.download {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.download__title {
  font-size: 36px;
  line-height: 30px;
  margin: 0 0 15px;
}
.download__block {
  background: #eee;
  display: flex;
  text-decoration: none;
}
.download__titlefilename {
  position: relative;
}
.download__info {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  min-height: 73px;
  background-color: var(--color-green);
  color: #fff;
  transition: background 0.3s;
}
html.non-touch .download__info:hover {
  background-color: var(--color-green-dark);
}
.download__image {
  display: block;
}
.download__downloadtitle {
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 120%;
}
.download__filename {
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
}
@media (max-width: 576px) {
  .download__image-container {
    display: none;
  }
  .download__block {
    margin-top: 20px;
  }
  .download__info {
    padding: 40px 25px;
  }
}