.p-home {
  overflow-x: hidden;
}
.p-home .swiper-container-horizontal > .swiper-pagination-bullets {
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}
.p-home__slideshow .swiper-container {
  top: -25px;
}
html.mt .p-home__slideshow .swiper-container {
  top: 0;
}
.p-home__slideshow .swiper-pagination-bullets {
  bottom: 75px;
}
.p-home__newsslideshow .slideshow {
  position: relative;
  top: -80px;
}
.p-home__greenbgblocklogoslider {
  position: relative;
  top: -80px;
  padding-bottom: 75px;
}
.p-home__greenbgblocklogoslider .logoslider {
  position: relative;
  top: -50px;
  z-index: 10;
  box-shadow: 0 17px 14px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1200px) {
  .p-home__slideshow .swiper-container {
    top: 0;
  }
}
html.mt .p-home > .greenbgblock {
  padding-bottom: 100px;
}
html.mt .p-home__greenbgblocklogoslider, html.mt .p-home__greenbgblocklogoslider .logoslider {
  top: 0;
}
html.mt .p-home__slideshow .swiper-pagination-bullets {
  bottom: 15px;
}
html.mt .p-home__socialwall {
  background: url("../../web/img/mt-bg.png") no-repeat;
  background-size: cover;
}
html.mt .p-home__occasions {
  display: flex;
  background-image: linear-gradient(180deg, #bbcf2f 0%, #85a215 100%);
  height: 739px;
}
html.mt .p-home .p-occasions {
  display: flex;
}
html.mt .p-home .p-occasions__left {
  width: 68%;
  display: flex;
  height: 100%;
}
html.mt .p-home .p-occasions__image {
  width: 100%;
  position: relative;
}
html.mt .p-home .p-occasions__overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 125px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
html.mt .p-home .p-occasions__container {
  display: flex;
  justify-content: space-between;
  padding-right: 53px;
  align-items: center;
  width: 894px;
}
html.mt .p-home .p-occasions__description {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}
html.mt .p-home .p-occasions__description--title {
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
}
html.mt .p-home .p-occasions__highlight {
  font-size: 42px;
  line-height: 100%;
  text-transform: uppercase;
  color: #fff;
}
html.mt .p-home .p-occasions__righttitle {
  font-size: 42px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 24px;
}
html.mt .p-home .p-occasions__rightcontainer {
  width: 282px;
}
html.mt .p-home .p-occasions__right {
  width: 32%;
  display: flex;
  flex-direction: column;
  padding: 88px;
}
html.mt .p-home .p-occasions__right .p-rightoccasions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
  overflow: hidden;
}
html.mt .p-home .p-occasions__right .p-rightoccasions a {
  text-decoration: none;
}
html.mt .p-home .p-occasions__right .p-rightoccasions__image {
  height: 124px;
}
html.mt .p-home .p-occasions__right .p-rightoccasions__description {
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 10px;
  color: #000;
}
html.mt .p-home .p-occasions__right .p-rightoccasions__description--title {
  font-size: 20px;
  line-height: 100%;
  font-family: "d-din-condensed-bold", Arial, sans-serif;
  font-weight: 700;
}
html.mt .p-home .p-occasions__right .p-rightoccasions__link {
  margin-top: 20px;
}
html.mt .p-home .p-searchform {
  display: flex;
  margin-top: 20px;
}
html.mt .p-home .p-searchform .wh-form__fieldgroup {
  flex-direction: column;
}
html.mt .p-home .p-searchform__searchcontainer {
  align-items: center;
}
html.mt .p-home .p-searchform__input {
  font-size: 14px;
  line-height: 18px;
  background: transparent;
  height: 46px;
  border: 1px solid #fff;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  padding: 14px 20px 14px 14px;
  margin-right: -18px;
  outline: none;
}
html.mt .p-home .p-searchform__button {
  border: 1px solid #fff;
  min-width: 100px;
  width: 100px;
}
@media screen and (max-width: 500px) {
  html.mt .p-home .p-searchform {
    display: none;
  }
}
html.mt .p-home .p-dealerresults__container {
  max-height: 400px;
  overflow-y: scroll;
}
html.mt .p-home .p-dealerresults__result {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  margin: 20px 60px 0 0;
  padding-bottom: 20px;
}
html.mt .p-home .p-dealerresults__data {
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
html.non-touch html.mt .p-home .p-dealerresults__data:hover {
  text-decoration: underline;
}
html.mt .p-home .p-dealerresults__title, html.mt .p-home .p-dealerresults__footer {
  font-weight: bold;
}
@media screen and (max-width: 500px) {
  html.mt .p-home .p-dealerresults__result:first-child {
    border-top: 1px solid #000;
    padding-top: 20px;
  }
}
html.mt .p-home .p-categories {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  display: flex;
  margin: 72px auto;
  justify-content: center;
}
@media (max-width: 1200px) {
  html.mt .p-home .p-categories {
    padding-left: 25px;
    padding-right: 25px;
  }
}
html.mt .p-home .p-categories__category {
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 282px;
}
html.mt .p-home .p-categories__image {
  display: block;
  width: 220px;
}
html.mt .p-home .p-categories__title {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 30px;
  font-weight: bold;
}
html.mt .p-home .logoslider__title {
  color: #000;
  border-top: 3px solid #000;
  padding-top: 15px;
}
html.mt .p-home .p-dealers__container {
  width: 500px;
}
html.mt .p-home .p-dealers__title {
  color: #000;
  font-size: 29px;
  line-height: 100%;
  font-family: "d-din-condensed-bold", Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.mt .p-home .p-dealers__subtitle {
  font-size: 60px;
  line-height: 55px;
  color: #000;
  font-family: "d-din-condensed-bold", Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}
html.mt .p-home .p-dealers__introduction {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin-right: 61px;
}
html.mt .p-home .p-dealers__left {
  display: flex;
  justify-content: flex-end;
  padding-top: 61px;
}
@media screen and (max-width: 1200px) {
  html.mt .p-home .p-occasions__right {
    padding: 40px;
  }
}
@media screen and (max-width: 1000px) {
  html.mt .p-home .p-home__occasions {
    flex-flow: row wrap;
  }
  html.mt .p-home .p-dealers__left {
    padding: 20px;
  }
  html.mt .p-home .p-occasions__left {
    width: 100%;
    height: 50%;
  }
  html.mt .p-home .p-occasions__right {
    width: 100%;
  }
  html.mt .p-home .p-occasions__right .p-rightoccasions {
    width: calc(50% - 20px);
    display: block;
    margin: 0 20px 0 0;
  }
  html.mt .p-home .p-occasions__rightcontainer {
    width: 100%;
    flex-flow: row wrap;
    display: flex;
  }
  html.mt .p-home .p-occasions__righttitle {
    width: 100%;
  }
}
@media screen and (max-width: 940px) {
  html.mt .p-home .p-occasions__container {
    padding: 0 50px;
  }
  html.mt .p-home .p-dealerresults__result {
    flex-direction: column;
  }
  html.mt .p-home .p-dealerresults__result {
    margin-right: 0;
  }
  html.mt .p-home .p-dealerresults__link {
    margin-top: 20px;
  }
  html.mt .p-home .p-categories {
    flex-flow: row wrap;
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: space-between;
  }
  html.mt .p-home .p-categories__title {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  html.mt .p-home .p-categories__category {
    width: calc(50% - 20px);
  }
  html.mt .p-home .p-categories__image {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  html.mt .p-home .p-searchform__searchcontainer {
    display: block;
    width: 100%;
  }
  html.mt .p-home .p-searchform__input {
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    width: 100%;
    margin-bottom: 10px;
  }
  html.mt .p-home .p-searchform__button {
    width: 100%;
    min-width: 100%;
  }
  html.mt .p-home .p-occasions__left {
    width: 100%;
    height: 40%;
  }
  html.mt .p-home .p-occasions__container {
    flex-direction: column;
    justify-content: space-evenly;
  }
  html.mt .p-home .p-occasions__highlight {
    font-size: 28px;
    line-height: 100%;
  }
  html.mt .p-home .p-occasions__description--title {
    font-size: 16px;
    line-height: 100%;
  }
}
@media screen and (max-width: 700px) {
  html.mt .p-home .p-categories__category {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  html.mt .p-home .p-dealers {
    flex-direction: column-reverse;
    height: auto;
  }
  html.mt .p-home .p-dealers__left, html.mt .p-home .p-dealers__right, html.mt .p-home .p-dealers__container {
    width: 100%;
  }
  html.mt .p-home .p-dealers__right {
    height: 400px;
  }
  html.mt .p-home .p-dealerresults__result {
    flex-direction: row;
  }
}
@media screen and (max-width: 480px) {
  html.mt .p-home .socialwall {
    padding-bottom: 80px;
  }
  html.mt .p-home .p-home__occasions {
    height: auto;
  }
  html.mt .p-home .p-occasions__left {
    min-height: 400px;
  }
  html.mt .p-home .p-occasions__overlay {
    height: 200px;
  }
  html.mt .p-home .p-occasions__right .p-rightoccasions {
    width: 100%;
    margin-bottom: 20px;
  }
  html.mt .p-home .slideshow__description {
    margin: 0 0 10px;
  }
  html.mt .p-home .slideshow__item {
    flex-direction: column-reverse;
    height: 600px;
    position: relative;
  }
  html.mt .p-home .slideshow__bgimage, html.mt .p-home .slideshow__info {
    width: 100%;
  }
  html.mt .p-home .slideshow__info {
    align-items: flex-start;
    position: absolute;
    background: transparent;
    color: #fff;
  }
  html.mt .p-home .slideshow__bgimage {
    height: 100%;
  }
  html.mt .p-home .slideshow__bgoverlay {
    display: block;
  }
  html.mt .p-home .p-categories__category {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  html.mt .p-home .p-dealerresults__result {
    flex-direction: column;
  }
}
.p-home .p-previewblocks {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  z-index: 100;
  position: relative;
  top: -50px;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .p-home .p-previewblocks {
    padding-left: 25px;
    padding-right: 25px;
  }
}
html.slideshow-no-slides .p-home .p-previewblocks {
  top: 20px;
}
.p-home .p-previewblocks .previewblock--facebook {
  padding: 0;
  width: 50%;
  background: transparent;
}
.p-home .p-previewblocks .previewblock--facebook > div {
  display: flex;
  justify-content: center;
}
.p-home .p-previewblocks .previewblock--occasions {
  width: 40%;
}
@media screen and (max-width: 599px) {
  .p-home .p-previewblocks {
    display: flex;
    flex-direction: column;
  }
  .p-home .p-previewblocks .previewblock {
    width: 100%;
  }
  .p-home .p-previewblocks .previewblock__button {
    display: flex;
    justify-content: center;
  }
}
.p-home .p-locations {
  display: flex;
  justify-content: space-between;
  margin-bottom: 110px;
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .p-home .p-locations {
    padding-right: 15px;
  }
  .p-home .p-locations .p-locations__location {
    padding-left: 15px;
  }
}
.p-home .p-locations__location {
  border-right: 1px solid #6b8f00;
  width: 384px;
}
.p-home .p-locations__image {
  display: block;
  width: 100%;
  margin: 0 0 55px;
}
.p-home .p-locations__text {
  padding-right: 15px;
}
.p-home .p-locations__text h1 {
  font-size: 29px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  font-size: 29px;
  line-height: 29px;
  margin: 0 0 5px;
}
@media (max-width: 576px) {
  .p-home .p-locations__text h1 {
    font-size: 15.95px;
    line-height: 15.95px;
  }
}
.p-home .p-locations__text h2 {
  font-size: 60px;
  line-height: 100%;
  color: var(--color-black);
  font-size: 60px;
  line-height: 60px;
  line-height: 55px;
  margin: 0 0 20px;
}
@media (max-width: 576px) {
  .p-home .p-locations__text h2 {
    font-size: 33px;
    line-height: 33px;
  }
}
@media screen and (max-width: 767px) {
  .p-home .p-locations {
    display: block;
  }
  .p-home .p-locations__location {
    width: 100%;
    border: 0;
    margin-bottom: 0;
  }
  .p-home .p-locations__image {
    margin-bottom: 25px;
  }
}
.p-home .p-dealers {
  display: flex;
  height: 725px;
}
.p-home .p-dealers__left {
  width: 40%;
  background-image: linear-gradient(-180deg, #bbcf2f 0%, #85a215 100%);
}
.p-home .p-dealers__right {
  width: calc(100% - 40%);
}
.p-home .p-dealers__map {
  width: 100%;
  height: 100%;
}

html.page-home .main {
  padding-top: 0;
  z-index: 600;
}
html.page-home.mt .main {
  z-index: 0;
}

html.page-home.has-scrolled .main {
  z-index: 0;
}