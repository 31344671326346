.mydialog {
  margin: 3rem;
  max-height: 100vh;
  overflow-y: auto;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.mydialog .dompack-messagebox__buttongroup {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.mydialog .dompack-messagebox__button {
  min-width: 100px;
  text-align: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0;
}

.mydialog__modalbg {
  background-color: rgba(0, 0, 0, 0.7);
}

.mydialog__holder {
  background: #fff;
  width: 860px;
  min-width: 400px;
  max-width: calc(100vw - 120px);
  overflow: visible;
}

.modal__closebutton {
  position: absolute;
  top: 20px;
  right: 0;
  transform: translateX(50%);
  line-height: 0;
  background: none;
  border: 0;
  cursor: pointer;
}
html .modal__closebutton .roundnavbutton {
  background: #6B8F00;
  border: 0;
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: fill 0.3s, background 0.3s;
  border-radius: 100%;
}
html .modal__closebutton .roundnavbutton:hover {
  border: 1px solid #6B8F00;
  background: #fff;
}
html .modal__closebutton .roundnavbutton:hover .icon-close .stroke {
  stroke: #6B8F00;
}