.slider {
  background: white;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider .slide-track {
  display: flex;
  width: 100%;
}
.slider .slide {
  width: 250px;
}

.logoslider {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  width: 100%;
  max-width: 1200px;
  position: relative;
  background: #fff;
  padding: 50px 0;
}
.logoslider__title {
  font-size: 27px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  text-align: center;
  margin: 0 0 50px;
}
.logoslider__logocontainer {
  display: flex !important;
  text-decoration: none;
  outline: none;
  height: 60px;
  align-items: center;
  justify-content: center;
}
.logoslider__exclusive {
  font-size: 15px;
  line-height: 100%;
  text-transform: uppercase;
  color: #CDCDCD;
  letter-spacing: 0.02px;
  text-align: center;
  max-width: 110px;
  margin: 30px auto 0;
}