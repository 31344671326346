@media print {
  body {
    color: #000 !important;
  }
  .main {
    margin-top: 0 !important;
  }
  .header,
  .footer,
  .menu,
  .wh-video,
  .breadcrumb,
  .embeddedobject--download {
    display: none !important;
  }
  .p-productpage__description {
    position: relative;
  }
  .embeddedobject {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid-page;
    position: relative;
  }
}