.emb-productblocks {
  border-top: 1px solid #a6a9a9;
}
.emb-productblocks .emb-block {
  padding: 15px 0;
  border-bottom: 1px solid #a6a9a9;
}
.emb-productblocks .emb-block__title {
  text-decoration: none;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  cursor: pointer;
  display: flex;
  position: relative;
  height: 40px;
  margin: 0;
  align-items: center;
}
html.non-touch .emb-productblocks .emb-block__title:hover {
  text-decoration: underline;
}
.emb-productblocks .emb-block__title::after {
  content: "";
  width: 11px;
  height: 6px;
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-down-green-small.svg") no-repeat;
  position: absolute;
  top: 50%;
  right: 10px;
  transition: transform 0.3s;
  transform: translateY(-50%);
}
.emb-productblocks .emb-block__inner {
  display: none;
  padding: 0 102px 35px 0;
}
.emb-productblocks .emb-block__text {
  width: 100%;
}
.emb-productblocks .emb-block__imagecontainer {
  margin-left: 30px;
  width: 282px;
}
.emb-productblocks .emb-block__image {
  display: block;
  max-width: 282px;
}
.emb-productblocks .emb-block--hasimg .emb-block__text {
  flex: 1;
}
.emb-productblocks .emb-block.expanded .emb-block__title::after {
  transform: translateY(-50%) scaleY(-1);
}
.emb-productblocks .emb-block.expanded .emb-block__inner {
  display: flex;
}
@media screen and (max-width: 650px) {
  .emb-productblocks .emb-block.expanded .emb-block__inner {
    display: block;
  }
}
@media screen and (max-width: 900px) {
  .emb-productblocks .emb-block__inner {
    padding-right: 0;
  }
}
@media screen and (max-width: 650px) {
  .emb-productblocks .emb-block__imagecontainer {
    margin-left: 0;
    width: 100%;
  }
  .emb-productblocks .emb-block__image {
    max-width: 100%;
  }
}

@media print {
  .emb-productblocks .emb-block__inner {
    display: flex !important;
    padding-right: 0;
  }
  .emb-productblocks .emb-block__title::after {
    display: none;
  }
}