.photoalbum .contentwrapper {
  position: relative;
}
.photoalbum__popup {
  display: none;
}
.photoalbum__galleries {
  position: relative;
}
.photoalbum__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.photoalbum__gallery-top,
.photoalbum__gallery-top .swiper-wrapper {
  width: 100%;
  height: 654px;
}
.photoalbum__gallery-bottom {
  width: 100%;
  height: auto;
  position: relative;
}
.photoalbum__gallery-bottom .swiper-container {
  margin: 27px 0 0;
}
.photoalbum__thumbs,
.photoalbum__thumbs .swiper-wrapper {
  width: 100%;
}
.photoalbum__thumbitem {
  width: 305px;
  height: 203px;
  cursor: pointer;
}
.photoalbum__thumbitem:not(.swiper-slide-active) {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.photoalbum__next, .photoalbum__prev {
  cursor: pointer;
  transition: opacity 0.3s;
}
.photoalbum__next,
.photoalbum__next > svg, .photoalbum__prev,
.photoalbum__prev > svg {
  display: block;
}
.photoalbum__next.disabled, .photoalbum__prev.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.photoalbum__next {
  transform: scaleX(-1);
  margin-left: 22px;
}
.photoalbum--vertical .photoalbum__gallery-top,
.photoalbum--vertical .photoalbum__gallery-top .swiper-wrapper {
  width: 768px;
}
.photoalbum--vertical .swiper-container {
  margin: 0;
}
.photoalbum--vertical .photoalbum__galleries {
  position: relative;
}
.photoalbum--vertical .photoalbum__gallery-bottom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 204px;
}
.photoalbum--vertical .photoalbum__thumbs {
  max-height: 500px;
}
.photoalbum--vertical .photoalbum__thumbs,
.photoalbum--vertical .photoalbum__thumbs .swiper-wrapper {
  width: 100%;
}
.photoalbum--vertical .swiper-button-next {
  position: absolute;
  top: auto;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  margin: 0 !important;
}
@media screen and (max-width: 1200px) {
  .photoalbum--vertical .photoalbum__gallery-top,
  .photoalbum--vertical .photoalbum__gallery-top .swiper-wrapper {
    width: 65%;
  }
  .photoalbum--vertical .photoalbum__gallery-bottom {
    width: calc(35% - 24px);
  }
}
@media (max-width: 768px) {
  .photoalbum--vertical > .contentwrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .photoalbum--vertical .photoalbum__gallery-top,
  .photoalbum--vertical .photoalbum__gallery-top .swiper-wrapper {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .photoalbum--vertical > .contentwrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .photoalbum__gallery-top,
  .photoalbum__gallery-top .swiper-wrapper {
    max-height: 50vh;
  }
  .photoalbum__gallery-bottom {
    display: none;
  }
  .photoalbum__mobilenav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .photoalbum__mobilenav--prev {
    transform: translateY(-50%) scaleX(-1);
  }
}